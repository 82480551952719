export default class FileUpload {
    constructor(options) {
        if(!options.upload_url) { throw new Error('Upload URL gerekli!'); }
        if(!options.location) { throw new Error('Location URL gerekli!'); }
        if(!options.icon_location) { throw new Error('Icon Location URL gerekli!'); }
        this.options = options || {};
        this.dropzone();
    }

    dropzone() {
        let id = this.options.id || '#image-dropzone';
        let maxFiles = this.options.max_files || 1;
        let addRemoveLinks = this.options.add_remove_links || true;
        let field_name = this.options.field_name || 'document[]';
        let upload_url = this.options.upload_url;
        let location = this.options.location;
        let files = this.options.files || null;
        let icon_location = this.options.icon_location || null;
        let uploadedFileMap = {};
        let zIndex = 99999;
        let width = this.options.width || null;
        let height = this.options.height || null;
        let fu = this;

        let dz = new Dropzone(id, {
            url: upload_url,
            maxFiles: maxFiles,
            addRemoveLinks: addRemoveLinks,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function(file, response) {
                $('form').append('<input type="hidden" name="'+field_name+'" value="' + response.name + '">');
                file.modified_name = response.name;
                uploadedFileMap[response.name] = response.name;
            },
            removedfile: function(file) {
                file.previewElement.remove();

                if(file.status === 'error') {
                    return false;
                }

                let name = uploadedFileMap[file.name];

                if(file.name) {
                    name = file.name;
                }
                if(file.modified_name) {
                    name = file.modified_name;
                }
                $('form').find('input[name="'+field_name+'"][value="'+name+'"]').remove();

                this.options.maxFiles = maxFiles;
            },
            init: function() {
                if(files) {
                    if (Array.isArray(files)) {
                        for (let i in files) {
                            let file = files[i];
                            let extension = file.name.split('.').pop();
                            if(extension === 'gif' || extension === 'jpeg' || extension === 'jpg' || extension === 'bmp' || extension === 'png' || extension === 'svg') {
                                this.displayExistingFile(file, location+file.name)
                            } else {
                                this.displayExistingFile(file, icon_location + '/images/icons/' + fu.getFileIcon(file.name))
                            }
                            this.files.push(file);
                            this.options.maxFiles = this.options.maxFiles - 1;
                            $('form').append('<input type="hidden" name="'+field_name+'" value="' + file.name + '">');
                        }
                    } else {
                        let file = files;
                        let extension = file.name.split('.').pop();
                        if(extension === 'gif' || extension === 'jpeg' || extension === 'jpg' || extension === 'bmp' || extension === 'png' || extension === 'svg') {
                            this.displayExistingFile(file, location+file.name)
                        } else {
                            this.displayExistingFile(file, icon_location + '/images/icons/' + fu.getFileIcon(file.name) +'?v='+Math.random())
                        }
                        this.files.push(file);
                        this.options.maxFiles = this.options.maxFiles - 1;
                        $('form').append('<input type="hidden" name="'+field_name+'" value="' + file.name + '">');
                    }
                }
            },
            transformFile: function(file, done) {
                if(fu.options.resize === true) {
                    if (file.type === 'image/gif' || file.type === 'image/jpeg' || file.type === 'image/bmp' || file.type === 'image/png') {
                        let myDropZone = this;
                        // Create image editor overlay
                        let modal = document.createElement('div');
                        modal.classList.add('image-loader-modal');
                        modal.style.zIndex = zIndex--;
                        document.body.appendChild(modal);

                        let modal_body = document.createElement('div');
                        modal_body.classList.add('modal-body');
                        modal.appendChild(modal_body);

                        let modal_content = document.createElement('div');
                        modal_content.classList.add('modal-content');
                        modal_body.appendChild(modal_content);

                        // create confirm button at top left of the viewport
                        let buttonConfirm = document.createElement('button');
                        buttonConfirm.style.position = 'absolute';
                        buttonConfirm.style.left = '10px';
                        buttonConfirm.style.top = '10px';
                        buttonConfirm.style.zIndex = 9999;
                        buttonConfirm.textContent = 'Tamam';
                        modal_body.appendChild(buttonConfirm);
                        buttonConfirm.addEventListener('click', function () {
                            let canvas = cropper.getCroppedCanvas({
                                width: width,
                                height: height,
                            })
                            canvas.toBlob(function (blob) {
                                myDropZone.createThumbnail(
                                    blob,
                                    myDropZone.options.thumbnailWidth,
                                    myDropZone.options.thumbnailHeight,
                                    myDropZone.options.thumbnailMethod,
                                    false,
                                    function (dataUrl) {
                                        myDropZone.emit('thumbnail', file, dataUrl);
                                        done(blob);
                                    });
                            }, 'image/png');

                            document.body.removeChild(modal);
                        });

                        let image = new Image();
                        image.src = URL.createObjectURL(file);
                        modal_content.appendChild(image);

                        let cropper = new Cropper(image, {
                            aspectRatio: width / height,
                            autoCropArea: 1,
                            movable: true,
                            cropBoxResizable: true,
                            viewMode: 0,
                            scalable: true,
                        });
                    } else {
                        done(file);
                    }
                }
                else {
                    done(file)
                }
            }
        })
    }

    getFileIcon(filename){
        // get file extension
        let extension = filename.split('.').pop();
        let icon = 'default.png';
        if(extension === 'xlsx' || extension === 'xls') {
            icon = 'excel.png';
        }
        if(extension === 'doc' || extension === 'docx') {
            icon = 'word.png';
        }
        if(extension === 'ppt' || extension === 'pptx') {
            icon = 'powerpoint.png';
        }
        if(extension === 'pdf') {
            icon = 'pdf.png';
        }
        if(extension === 'zip') {
            icon = 'zip.png';
        }
        if(extension === 'rar') {
            icon = 'rar.png';
        }
        return icon;
    }
}
