require('./bootstrap');

if ($.fn.dataTable) {
    $.extend($.fn.dataTableExt.oStdClasses, {
        "sFilterInput": "form-control",
    });
}

window.TableSorter = require("./TableSorter");

$(document).ready(function () {
    $("#side-menu").metisMenu();

    let width = $(".main_button_holder").width();

    if (width) {
        let style = $("<style>.search_place_holder { width: " + width + "px; }</style>");
        $('html > head').append(style);
    }

    $("#vertical-menu-btn").on('click', function (event) {
        event.preventDefault();
        $('body').toggleClass('sidebar-enable');
        if ($(window).width() >= 992) {
            $('body').toggleClass('vertical-collpsed');
        } else {
            $('body').removeClass('vertical-collpsed');
        }
    })
})


if ($('select.select2').length) {
    $('.select2').select2({
        placeholder: "Please Select",
        allowClear: true
    });
}


$(document).on("mousedown", "table.rowClick tbody tr", function (event) {

    let url = $(this).data('href');

    if (url) {
        switch (event.button) {
            case 0:
            default:
                window.location.href = url;
                break;
            case 1:
                window.open(url, '_blank');
                break;
            case 2:
                break;

        }
    }
})

if ($(".texteditor").length) {
    tinymce.init({
        selector: "textarea.texteditor",
        height: 300,
        plugins: [
            "advlist autolink link lists charmap print preview hr anchor pagebreak spellchecker",
            "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
            "save table contextmenu directionality emoticons template paste textcolor"
        ],
        toolbar: "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | print preview media fullpage | forecolor backcolor emoticons",
        style_formats: [
            {title: 'Bold text', inline: 'b'},
            {title: 'Red text', inline: 'span', styles: {color: '#ff0000'}},
            {title: 'Red header', block: 'h1', styles: {color: '#ff0000'}},
            {title: 'Example 1', inline: 'span', classes: 'example1'},
            {title: 'Example 2', inline: 'span', classes: 'example2'},
            {title: 'Table styles'},
            {title: 'Table row 1', selector: 'tr', classes: 'tablerow1'}
        ]
    })
}

var cssRule1 =
    "color: rgb(200, 0, 0);" +
    "font-size: 30px;" +
    "font-weight: bold;";
var cssRule2 =
    "color: rgb(50,50,50);" +
    "font-size: 16px;";

setTimeout(console.log.bind(console, "%cDELTA AJANS!", cssRule1), 0);
setTimeout(console.log.bind(console, "%cBu, geliştiriciler için tasarlanmış bir tarayıcı özelliğidir. Biri sana bu sistemi ele geçirmek için bir şeyi kopyalayıp buraya yapıştırmanı söylediyse, bu bir dolandırıcılıktır ve bunu yapmanı söyleyen kişi sen bunu yaptığında senin bilgilerine erişebilecektir.", cssRule2), 0);

Waves.init();

// show password input value
$("#password-addon").on('click', function () {
    if ($(this).siblings('input').length > 0) {
        $(this).siblings('input').attr('type') == "password" ? $(this).siblings('input').attr('type', 'input') : $(this).siblings('input').attr('type', 'password');
    }
})
