export default class TableSorter {
    constructor(options) {
        if(!options.route) { throw new Error('Route gerekli'); }
        if(!options.tableID) { throw new Error('tableID gerekli'); }
        this.options = options || {};
    }

    getTable() {
        return $("#"+this.options.tableID);
    }

    changeOrder(details) {

            let rows = [];
            let pageInfo = window.LaravelDataTables[this.options.tableID].page.info();
            details.forEach(element => {
                rows.push({
                    id: $(element.node).data('entry-id'),
                    position: element.newPosition
                });
            });

            axios.post(this.options.route, {rows, pageInfo})
                .then(response => {
                    this.getTable().DataTable().draw(false);
                })
    }
}
